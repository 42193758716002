import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  AnyAction,
  CombinedState,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import loginReducer, { LoginState } from "../features/login/loginSlice";
import customization, {
  CustomizationState,
} from "../features/customization/customizationSlice";
import commonReducer, { CommonState } from "../features/common/commonSlice";
import magicFormsReducer, {
  MagicFormsState,
} from "../features/services/magicforms/magicFormsSlice";
import firmaReducer, {
  FirmaState,
} from "../features/services/firma/firmaSlice";
import coreIdReducer, {
  CoreIdState,
} from "../features/services/coreid/coreIdSlice";
import alertsReducer, {
  AlertsState,
} from "../features/services/alerts/alertsSlice";
import generalReducer, {
  GeneralState,
} from "../features/services/components/GeneralSlice";
import firmaNewReducer, {
  FirmaNewState,
} from "../features/services/firmanew/firmaNewSlice";
import hyperFlowReducer, {
  HyperFlowState,
} from "../features/services/hyperFlow/hyperFlowSlice";
import busquedaReducer, {
  BusquedaState,
} from "../features/services/components/BusquedaSlice";
import smartFlowReducer, {
  SmartFlowState,
} from "../features/services/smartflow/smartFlowSlice";
import pagareReducer, {
  PagareState,
} from "../features/services/pagares/pagareSlice";
import configurationReducer, {
  ConfigurationState,
} from "../features/configuration/configurationSlice";
import analyticsReducer, {
  AnalyticsState,
} from "../features/services/analytics/analyticsSlice";
import clientsSlice, {
  ClientState,
} from "../features/configuration/clients/clientsSlice";
import tagSlice, { TagState } from "../features/services/tags/tagSlice";
import storage from "redux-persist/lib/storage";
import { persistStore } from "redux-persist";
import liveMonitoringSlice, {
  LiveMonitoringState,
} from "../features/services/liveMonitoring/liveMonitoringSlice";
import mfEditorSlice, {
  MfEditorState,
} from "../features/services/magicforms/editor/mfEditorSlice";
import AuthentikatorSlice, {
  authentikatorSlice,
  AuthentikatorState,
} from "../features/services/authentikator/AuthentikatorSlice";
import dashboardReducer, {
  DashboardState,
} from "../features/services/repoteria/DashboardSlice";
import hfEditorReducer, {
  hfEditorSlice,
  HfEditorState,
} from "../features/services/hyperFlow/editor/hfEditorSlice";

import billingReducer, { BilingState } from "../features/services/facturacion/BillingSlice";

const reducers = combineReducers({
  login: loginReducer,
  customize: customization,
  common: commonReducer,
  magicForms: magicFormsReducer,
  firma: firmaReducer,
  firmaNew: firmaNewReducer,
  coreId: coreIdReducer,
  busqueda: busquedaReducer,
  alerts: alertsReducer,
  hyperFlow: hyperFlowReducer,
  general: generalReducer,
  smartFlow: smartFlowReducer,
  pagare: pagareReducer,
  configuration: configurationReducer,
  analytics: analyticsReducer,
  clients: clientsSlice,
  tags: tagSlice,
  liveMonitoring: liveMonitoringSlice,
  mfEditor: mfEditorSlice,
  authentikator: AuthentikatorSlice,
  dashboard: dashboardReducer,
  facturacion: billingReducer,
  hfEditor: hfEditorReducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        login: LoginState;
        customize: CustomizationState;
        common: CommonState;
        magicForms: MagicFormsState;
        firma: FirmaState;
        firmaNew: FirmaNewState;
        coreId: CoreIdState;
        busqueda: BusquedaState;
        alerts: AlertsState;
        hyperFlow: HyperFlowState;
        general: GeneralState;
        smartFlow: SmartFlowState;
        pagare: PagareState;
        configuration: ConfigurationState;
        analytics: AnalyticsState;
        clients: ClientState;
        tags: TagState;
        liveMonitoring: LiveMonitoringState;
        mfEditor: MfEditorState;
        authentikator: AuthentikatorState;
        dashboard: DashboardState;
        facturacion: BilingState;
        hfEditor: HfEditorState;
      }>
    | undefined,
  action: AnyAction
) => {
  if (action.type === "common/resetState") {
    state = undefined;
  }

  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["common", "firmaNew"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
