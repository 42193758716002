import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  Bill,
  billClientAPI,
  BillInfoRequest,
  BillInfoResponse,
  BillingOptions,
  ChangeReportFieldsReq,
  changeReportOptionsAPI,
  changeUnbillableAPI,
  ChangeUnbillableReq,
  ClientsResponse,
  confirmBillAPI,
  FilteredRequest,
  GeneralConfig,
  GeneralConfigByClient,
  GeneralIndicatorsResponse,
  generateDetailReportAPI,
  getBillingOptionsAPI,
  getDataClientsAPI,
  getGeneralConfigAPI,
  getGeneralConfigByClientAPI,
  getGeneralIndicatorsAPI,
  getReportOptionsAPI,
  ReportOptionsResponse,
  updateGeneralConfigAPI,
} from "./BillingAPI";
import { LoadingStatus } from "../../common/commonSlice";
import {
  hyperFlowStepNameMap,
  mapAllServices,
  serviceIdToNameMap,
  startBillingNameMap,
  transformKeysWithMap,
} from "./helpers/utils";

export type BillingActiveComponent =
  | "General"
  | "Analytics"
  | "Reports"
  | "Config"
  | "none";

export type SelectedVisualization =
  | "Clienteyservicio"
  | "Clientes"
  | "Servicios";

export const dataGeneralIndicator = createAsyncThunk(
  "billing/getdataGeneralIndicators",
  async (
    params: { dataFiltered: FilteredRequest | null },
    { rejectWithValue, getState }
  ) => {
    try {
      const { data } = await getGeneralIndicatorsAPI(params.dataFiltered);
      const { facturacion } = getState() as RootState;
      const { dataClients } = facturacion;

      if (!dataClients) {
        return null;
      }

      if (data) {
        const clientIdToNameMap: Record<string, string> = {};

        dataClients.forEach((client) => {
          clientIdToNameMap[client.id] = client.name;
        });

        data.clientCount = transformKeysWithMap(
          data.clientCount,
          clientIdToNameMap
        );
        data.clientDateCount = transformKeysWithMap(
          data.clientDateCount,
          clientIdToNameMap
        );

        data.serviceCount = transformKeysWithMap(
          data.serviceCount,
          serviceIdToNameMap
        );
        data.serviceDateCount = transformKeysWithMap(
          data.serviceDateCount,
          serviceIdToNameMap
        );
      }

      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getDataClients = createAsyncThunk(
  "billing/getDataClients",
  async () => {
    try {
      const { data } = await getDataClientsAPI();
      return data;
    } catch (err: any) {
      return err.message;
    }
  }
);

export const generateDetailReport = createAsyncThunk(
  "billing/generateReport",
  async (
    params: { dataFiltered: FilteredRequest | null },
    { rejectWithValue }
  ) => {
    try {
      const { dataFiltered } = params;
      const { data } = await generateDetailReportAPI(dataFiltered);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getBillingOptions = createAsyncThunk(
  "billing/getBillingOptions",
  async (clientId: string, { rejectWithValue }) => {
    try {
      const res = await getBillingOptionsAPI(clientId);
      if (res) {
        return res;
      } else {
        return rejectWithValue("No se encontraron opciones de facturación");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getReportOptions = createAsyncThunk(
  "billing/getReportOptions",
  async (clientId: string, { rejectWithValue }) => {
    try {
      const res = await getReportOptionsAPI(clientId);
      if (res) {
        return res;
      } else {
        return rejectWithValue("No se encontraron opciones de facturación");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const billClient = createAsyncThunk(
  "billing/billClient",
  async (
    req: {
      dateToGenerate?: string;
      billedId?: string;
      generateInvoice?: boolean;
      generateReport?: boolean;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const clientId = state.facturacion.currentClientId;
      let dateToGeneretReq = state.facturacion.selectedPeriod?.dateToGenerate;
      if (req.dateToGenerate) {
        dateToGeneretReq = req.dateToGenerate;
      }
      let billedBill: Bill | undefined = undefined;
      if (req.billedId) {
        const billingOptions = state.facturacion.billingOptions;
        if (billingOptions) {
          billedBill = billingOptions.bills?.find(
            (bill) => bill.id === req.billedId
          );
        }
      }
      const reqToSend: BillInfoRequest = {
        dateToGenerate: dateToGeneretReq,
        generateInvoice: req.generateInvoice,
        generateReport: req.generateReport,
        clientId: clientId!,
        billedBill,
      };
      const res = await billClientAPI(reqToSend);
      if (res) {
        return res;
      } else {
        return rejectWithValue("No se encontraron opciones de facturación");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const confirmBill = createAsyncThunk(
  "billing/confirmBill",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState() as RootState;
      const selectedPeriod = state.facturacion.selectedPeriod;
      if (selectedPeriod?.billGenerated) {
        const res = await confirmBillAPI(selectedPeriod.billGenerated);
        if (res) {
          return res;
        } else {
          return rejectWithValue("Hubo un problema confirmando la factura");
        }
      } else {
        return rejectWithValue("No se puede facturar");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeUnbillable = createAsyncThunk(
  "billing/changeUnbillable",
  async (
    req: { entityId: string; unbillable: boolean; reason: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const clientId = state.facturacion.currentClientId;
      const service = state.facturacion.currentReportService;
      const reqToSend: ChangeUnbillableReq = {
        entityId: req.entityId,
        unbillable: req.unbillable,
        reason: req.reason,
        clientId: clientId!,
        service: service!,
      };
      const res = await changeUnbillableAPI(reqToSend);
      if (res) {
        return res;
      } else {
        return rejectWithValue("No se encontraron opciones de facturación");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeReportOptions = createAsyncThunk(
  "billing/changeReportOptions",
  async (req: ChangeReportFieldsReq, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const clientId = state.facturacion.currentClientId;
      var reqToSend: ChangeReportFieldsReq = {
        clientId: clientId!,
        newReportFields: req.newReportFields,
      };
      const res = await changeReportOptionsAPI(reqToSend);
      if (res) {
        return res;
      } else {
        return rejectWithValue("No se encontraron opciones de facturación");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getGeneralConfig = createAsyncThunk(
  "billing/getGeneralConfig",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getGeneralConfigAPI();
      return data;
    } catch (err: any) {
      rejectWithValue(err.message);
    }
  }
);

export const updateGeneralConfig = createAsyncThunk(
  "billing/updateGeneralConfig",
  async (value: number, { rejectWithValue }) => {
    try {
      const { data } = await updateGeneralConfigAPI(value);
      return data;
    } catch (err: any) {
      rejectWithValue(err.message);
    }
  }
);

export const getGeneralConfigByClient = createAsyncThunk(
  "billing/generalConfigByClient",
  async (id: string, { rejectWithValue }) => {
    try {
      const { clientConfig } = await getGeneralConfigByClientAPI(id);

      if (!clientConfig) {
        return null;
      }

      if (clientConfig) {
        clientConfig.services = transformKeysWithMap(
          clientConfig.services,
          serviceIdToNameMap
        );

        clientConfig.services = mapAllServices(
          clientConfig.services,
          hyperFlowStepNameMap,
          startBillingNameMap
        );
      }
      return clientConfig;
    } catch (err: any) {
      return err.message;
    }
  }
);

export interface BilingState {
  activeComponent: BillingActiveComponent;
  selectedVisualization: SelectedVisualization;
  generalIndicators: GeneralIndicatorsResponse | null;
  originalServiceDateCount: Record<string, Record<string, number>>;
  dataClients: ClientsResponse | null;
  filters: FilteredRequest;
  loading: LoadingStatus;
  generateReport: LoadingStatus;
  generalConfig: GeneralConfig | null;
  generalConfigByClient: GeneralConfigByClient | null;
  nameClient: string;
  billingOptions: BillingOptions | null;
  loadingBillingOptions: LoadingStatus;
  currentClientId: string | null;
  currentReportOptions: ReportOptionsResponse | null;
  configClientId: string | null;
  loadingReportOptions: LoadingStatus;
  billInfo: BillInfoResponse | null;
  loadingBillInfo: LoadingStatus;
  currentReport: Array<Record<string, any>> | null;
  currentReportService: string | null;
  showReportFieldsModal: boolean;
  selectedPeriod: {
    dateToGenerate?: string;
    billingId?: string;
    billGenerated?: Bill;
  } | null;
  showModalFactura: boolean;
  facturaPdf: string | undefined;
  error: string | null;
}

const initialState: BilingState = {
  activeComponent: "none",
  selectedVisualization: "Clientes",
  generalIndicators: {
    total: 0,
    estimated: 0,
    valueToGoal: 0,
    percentChange: 0,
    clientCount: {},
    serviceCount: {},
    clientDateCount: {},
    serviceDateCount: {},
    dateCount: {},
    monthOrWeeklyStart: "",
    monthOrWeeklyEnd: "",
  },
  originalServiceDateCount: {},
  dataClients: null,
  filters: {
    startDate: null,
    endDate: null,
    simpleDateFilter: null,
    clientId: null,
    hfId: null,
    accumulated: false,
    visualizationType: null,
  },
  nameClient: "",
  loading: "idle",
  generateReport: "idle",
  currentClientId: null,
  billingOptions: null,
  loadingBillingOptions: "idle",
  currentReportOptions: null,
  loadingReportOptions: "idle",
  billInfo: null,
  currentReport: null,
  configClientId: null,
  currentReportService: null,
  loadingBillInfo: "idle",
  generalConfig: null,
  generalConfigByClient: null,
  showReportFieldsModal: false,
  selectedPeriod: null,
  showModalFactura: false,
  facturaPdf: undefined,
  error: null,
};

const BillingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    changeActiveComponent: (
      state,
      action: PayloadAction<BillingActiveComponent>
    ) => {
      state.activeComponent = action.payload;
    },

    changeCurrentFilters: (state, action: PayloadAction<FilteredRequest>) => {
      state.filters = action.payload;
    },

    setVisualization: (state, action: PayloadAction<SelectedVisualization>) => {
      state.selectedVisualization = action.payload;
    },
    setSelectService: (state, action: PayloadAction<string>) => {
      const selectedService = action.payload;

      if (selectedService && state.generalIndicators) {
        const originalData = state.originalServiceDateCount;

        state.generalIndicators.serviceDateCount = originalData[selectedService]
          ? { [selectedService]: originalData[selectedService] }
          : {};
      } else {
        if (state.generalIndicators) {
          state.generalIndicators.serviceDateCount =
            state.originalServiceDateCount;
        }
      }
    },
    setNameClient: (state, action) => {
      state.nameClient = action.payload;
    },
    setCurrentClientId: (state, action) => {
      state.currentClientId = action.payload;
    },
    setConfigClientId: (state, action: PayloadAction<string | null>) => {
      state.configClientId = action.payload;
    },
    setCurrentReport: (
      state,
      action: PayloadAction<{ report: any; name: string }>
    ) => {
      state.currentReport = action.payload.report;
      state.currentReportService = action.payload.name;
    },

    setShowReportFieldsModal: (state, action: PayloadAction<boolean>) => {
      state.showReportFieldsModal = action.payload;
    },
    setReportOptions: (state, action: PayloadAction<ReportOptionsResponse>) => {
      state.currentReportOptions = action.payload;
    },
    setShowModalFactura: (state, action: PayloadAction<boolean>) => {
      state.showModalFactura = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(dataGeneralIndicator.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(dataGeneralIndicator.fulfilled, (state, action) => {
        state.loading = "resolved";
        state.generalIndicators = action.payload;
        state.originalServiceDateCount = action.payload?.serviceDateCount || {};
        state.error = null;
      })
      .addCase(dataGeneralIndicator.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = "unknow error fetch api";
      });
    builder
      .addCase(getDataClients.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getDataClients.fulfilled, (state, action) => {
        state.loading = "resolved";
        state.dataClients = action.payload;
        state.error = null;
      })
      .addCase(getDataClients.rejected, (state) => {
        state.loading = "rejected";
        state.error = "unknow error fetch api";
      });
    builder
      .addCase(generateDetailReport.fulfilled, (state) => {
        state.generateReport = "resolved";
      })
      .addCase(generateDetailReport.rejected, (state) => {
        state.generateReport = "rejected";
      })
      .addCase(generateDetailReport.pending, (state) => {
        state.generateReport = "pending";
      });

    // Billing Options
    builder
      .addCase(getBillingOptions.fulfilled, (state, action) => {
        state.billingOptions = action.payload;
        state.loadingBillingOptions = "resolved";
      })
      .addCase(getBillingOptions.rejected, (state, action) => {
        state.billingOptions = null;
        state.loadingBillingOptions = "rejected";
      })
      .addCase(getBillingOptions.pending, (state) => {
        state.loadingBillingOptions = "pending";
      });

    // Report options
    builder
      .addCase(getReportOptions.fulfilled, (state, action) => {
        state.currentReportOptions = action.payload;
        state.loadingReportOptions = "resolved";
      })
      .addCase(getReportOptions.rejected, (state, action) => {
        state.currentReportOptions = null;
        state.loadingReportOptions = "rejected";
      })
      .addCase(getReportOptions.pending, (state) => {
        state.currentReportOptions = null;
        state.loadingReportOptions = "pending";
      });

    // Bill info
    builder
      .addCase(billClient.fulfilled, (state, action) => {
        state.billInfo = action.payload;
        if (action.payload.reports) {
          const defaultReport = Object.entries(action.payload.reports).at(0);
          if (defaultReport) {
            state.currentReport = defaultReport[1];
            state.currentReportService = defaultReport[0];
          }
        }
        state.facturaPdf = action.payload.preFacturaBase64;
        state.selectedPeriod = {
          dateToGenerate: action.meta.arg.dateToGenerate
            ? action.meta.arg.dateToGenerate
            : state.selectedPeriod?.dateToGenerate,
          billingId: action.meta.arg.billedId,
          billGenerated: action.payload.generatedBill,
        };
        if (action.payload.preFacturaBase64) {
          state.showModalFactura = true;
        }
        state.loadingBillInfo = "resolved";
      })
      .addCase(billClient.rejected, (state) => {
        state.billInfo = null;
        state.currentReport = null;
        state.loadingBillInfo = "rejected";
      })
      .addCase(billClient.pending, (state) => {
        state.loadingBillInfo = "pending";
      });
    builder
      .addCase(getGeneralConfig.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getGeneralConfig.fulfilled, (state, action) => {
        const config = action.payload;
        if (config) {
          state.generalConfig = config;
          state.loading = "resolved";
        }
      })
      .addCase(getGeneralConfig.rejected, (state) => {
        state.loading = "rejected";
      });
    builder
      .addCase(updateGeneralConfig.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateGeneralConfig.fulfilled, (state) => {
        state.loading = "resolved";
      })
      .addCase(updateGeneralConfig.rejected, (state) => {
        state.error = "se presento un error";
      });
    builder
      .addCase(getGeneralConfigByClient.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getGeneralConfigByClient.fulfilled, (state, action) => {
        state.generalConfigByClient = action.payload;
        state.loading = "resolved";
      })
      .addCase(getGeneralConfigByClient.rejected, (state) => {
        state.loading = "rejected";
      });

    builder
      .addCase(changeReportOptions.fulfilled, (state, action) => {
        state.currentReportOptions = action.payload;
        state.loadingReportOptions = "resolved";
      })
      .addCase(changeReportOptions.rejected, (state) => {
        state.currentReportOptions = null;
        state.loadingReportOptions = "rejected";
      })
      .addCase(changeReportOptions.pending, (state) => {
        state.loadingReportOptions = "pending";
      });

    builder
      .addCase(confirmBill.pending, (state) => {
        state.loadingBillInfo = "pending";
      })
      .addCase(confirmBill.fulfilled, (state, action) => {
        state.billInfo = {
          ...state.billInfo,
          preFacturaBase64: action.payload.preFacturaBase64,
          generatedBill: action.payload.generatedBill,
        };
        if (action.payload.reports) {
          const defaultReport = Object.entries(action.payload.reports).at(0);
          if (defaultReport) {
            state.currentReport = defaultReport[1];
            state.currentReportService = defaultReport[0];
          }
        }
        state.facturaPdf = action.payload.preFacturaBase64;
        state.selectedPeriod = {
          billingId: action.payload.generatedBill?.id,
          billGenerated: action.payload.generatedBill,
        };
        if (action.payload.generatedBill) {
          state.showModalFactura = true;
        }
        state.loadingBillInfo = "resolved";
      })
      .addCase(confirmBill.rejected, (state) => {
        state.loadingBillInfo = "rejected";
      });
  },
});

export const {
  changeActiveComponent,
  changeCurrentFilters,
  setVisualization,
  setSelectService,
  setNameClient,
  setCurrentClientId,
  setCurrentReport,
  setShowReportFieldsModal,
  setReportOptions,
  setShowModalFactura,
  setConfigClientId,
} = BillingSlice.actions;

export const selectVisualization = (state: RootState) =>
  state.facturacion.selectedVisualization;

export const selectCurrentFiltered = (state: RootState) =>
  state.facturacion.filters;

export const selectBillingComponent = (state: RootState) =>
  state.facturacion.activeComponent;

export const selectDataIndicators = (state: RootState) =>
  state.facturacion.generalIndicators;

export const selectDataClients = (state: RootState) =>
  state.facturacion.dataClients;

export const selectLoading = (state: RootState) => state.facturacion.loading;

export const selectGenerateReport = (state: RootState) =>
  state.facturacion.generateReport;

export const selectServiceList = (state: RootState) =>
  state.facturacion.originalServiceDateCount;

export const selectNameClient = (state: RootState) =>
  state.facturacion.nameClient;

export const selectFilters = (state: RootState) => state.facturacion.filters;

export const selectBillingOptions = (state: RootState) =>
  state.facturacion.billingOptions;
export const selectLoadingBillingOptions = (state: RootState) =>
  state.facturacion.loadingBillingOptions;
export const selectCurrentBillingClientId = (state: RootState) =>
  state.facturacion.currentClientId;
export const selectCurrentReportOptions = (state: RootState) =>
  state.facturacion.currentReportOptions;
export const selectLoadingReportOptions = (state: RootState) =>
  state.facturacion.loadingReportOptions;
export const selectBillInfo = (state: RootState) => state.facturacion.billInfo;
export const selectLoadingBillInfo = (state: RootState) =>
  state.facturacion.loadingBillInfo;
export const selectCurrentReport = (state: RootState) =>
  state.facturacion.currentReport;
export const selectCurrentReportService = (state: RootState) =>
  state.facturacion.currentReportService;

export const selectGeneralConfig = (state: RootState) =>
  state.facturacion.generalConfig;

export const selectGeneralConfigByClient = (state: RootState) =>
  state.facturacion.generalConfigByClient;

export const selectShowModalReportFields = (state: RootState) =>
  state.facturacion.showReportFieldsModal;

export const selectCurrentPeriod = (state: RootState) =>
  state.facturacion.selectedPeriod;

export const selectShowModalFactura = (state: RootState) =>
  state.facturacion.showModalFactura;

export const selectFacturaPdf = (state: RootState) =>
  state.facturacion.facturaPdf;

export const selectConfigClientId = (state: RootState) =>
  state.facturacion.configClientId;

export default BillingSlice.reducer;
