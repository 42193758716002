import React from "react";

interface GeneralIndicatorsCardProps {
  title: string;
  value: number;
  isPercentage?: boolean;
  hideIfNegativeOne?: boolean;
  colorRules?: {
    positive: string;
    negative: string;
    zero: string;
  };
}

export const GeneralIndicatorsCard: React.FC<GeneralIndicatorsCardProps> = ({
  title,
  value,
  isPercentage = false,
  hideIfNegativeOne = false,
  colorRules = {
    positive: "#FFFFFF",
    negative: "",
    zero: "#FFFFFF",
  },
}) => {
  if (hideIfNegativeOne && value === -1) return null;

  const formatValue = (num: number): string => {
    if (isPercentage) {
      return `${num > 0 ? "+" : num < 0 ? "-" : ""}${Math.abs(num).toFixed(
        2
      )}%`;
    }

    if (Math.abs(num) >= 1000000) {
      return `$${(num / 1000000).toFixed(2)} M`;
    }

    return `$${num.toLocaleString()}`;
  };

  const getColor = (): string => {
    if (value > 0) return colorRules.positive;
    if (value < 0) return colorRules.negative;
    return colorRules.zero;
  };

  const renderValue = (): string => {
    if (value === 0 && colorRules.zero === "#ffffff") return "Meta cumplida";
    return formatValue(value);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(0, 0, 0, 0.1)",
          padding: "15px",
          borderRadius: "8px",
          color: getColor(),
          textAlign: "center",
          fontSize: "1.5rem",
          height: "122px",
        }}
      >
        <p
          style={{
            margin: "10px",
            fontSize: renderValue() === "Meta cumplida" ? "20px" : "40px",
            fontWeight: "700",
            lineHeight: "65.37px",
            textAlign: "center",
          }}
        >
          {renderValue()}
        </p>
        <h2
          style={{
            margin: "0",
            color: "white",
            fontSize: "10px",
            fontWeight: "700",
          }}
        >
          {title}
        </h2>
      </div>
    </>
  );
};
