import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  changeReportOptions,
  changeUnbillable,
  confirmBill,
  getDataClients,
  selectBillingComponent,
  selectCurrentPeriod,
  selectCurrentReport,
  selectCurrentReportOptions,
  selectCurrentReportService,
  selectDataClients,
  selectFacturaPdf,
  selectLoading,
  selectLoadingBillInfo,
  selectShowModalFactura,
  selectShowModalReportFields,
  setCurrentReport,
  setReportOptions,
  setShowModalFactura,
  setShowReportFieldsModal,
} from "../BillingSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import { ClipLoader } from "react-spinners";
import {
  base64PDFToBlobUrl,
  formatDateStandar,
} from "../../../../helpers/utils";
import { serviceIdToName } from "../helpers/utils";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Modal, { Styles } from "react-modal";
import { ReportField } from "../BillingAPI";
import { useNavigate } from "react-router-dom";
const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "58%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "70%",
    width: "79%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
const customStylesModal2: Styles = {
  content: {
    top: "53%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "90%",
    width: "50%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");
export const ReportsBilling = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const clients = useAppSelector(selectDataClients);
  const loading = useAppSelector(selectLoading);
  const loadingBillInfo = useAppSelector(selectLoadingBillInfo);
  const currentReport = useAppSelector(selectCurrentReport);
  const currentReportService = useAppSelector(selectCurrentReportService);
  const activeComponent = useAppSelector(selectBillingComponent);
  const showModalReportFields = useAppSelector(selectShowModalReportFields);
  const currentReportOptions = useAppSelector(selectCurrentReportOptions);
  const showModalFactura = useAppSelector(selectShowModalFactura);
  const facturaPDF = useAppSelector(selectFacturaPdf);
  const selectedPeriod = useAppSelector(selectCurrentPeriod);

  const [currentReportItem, setCurrentReportItem] = useState<any | null>(null);
  const [currentColumnsReportItem, setCurrentColumnsReportItem] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (activeComponent !== "Reports") {
      dispatch(changeActiveComponent("Reports"));
    }
    if (!clients || clients.length === 0) {
      dispatch(getDataClients());
    }
  }, [activeComponent]);

  const showFactura = () => {
    if (loadingBillInfo === "pending") {
      return <ClipLoader color="#fff" size={"50px"} />;
    } else {
      if (!facturaPDF)
        return (
          <h3 style={{ color: "black" }}>
            No se encontró el pdf de la factura
          </h3>
        );
      const url = base64PDFToBlobUrl(facturaPDF);
      return (
        <object
          data={url}
          type="application/pdf"
          width="100%"
          height="100%"
        ></object>
      );
    }
  };

  const showReportItem = () => {
    if (currentReportItem && currentColumnsReportItem) {
      return currentColumnsReportItem.map((column, i) => {
        if (column.includes("Fecha")) {
          return (
            <tr key={i} style={{ fontSize: "13px", color: "white" }}>
              <td style={{ fontWeight: "700" }}>{column}</td>
              <td>{formatDateStandar(currentReportItem[column])}</td>
            </tr>
          );
        } else if (column.includes("Facturable")) {
          return (
            <tr key={i} style={{ fontSize: "13px", color: "white" }}>
              <td style={{ fontWeight: "700" }}>{column}</td>
              <td>{currentReportItem[column] ? "SI" : "NO"}</td>
            </tr>
          );
        } else if (typeof currentReportItem[column] === "object") {
          return <></>;
        } else {
          return (
            <tr key={i} style={{ fontSize: "13px", color: "white" }}>
              <td style={{ fontWeight: "700" }}>{column}</td>
              <td>{currentReportItem[column]}</td>
            </tr>
          );
        }
      });
    } else {
      return <div></div>;
    }
  };

  const generateReportTable = () => {
    if (currentReport && currentReport.length > 0) {
      const commonColumns = Object.keys(currentReport[0]);
      return (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginLeft: "1%",
          }}
        >
          <thead
            className="text-normal text-medium primary-color-35"
            style={{
              background: "rgba(0,0,0,0.4)",
              height: "30px",
              textAlign: "center",
            }}
          >
            <tr>
              <th></th>
              {commonColumns.map((column) => (
                <th>{column}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody
            className="text-normal text-x-small primary-color"
            style={{ background: "rgba(0,0,0,0.2)", textAlign: "center" }}
          >
            <tr>
              <td></td>
              {commonColumns.map(() => (
                <td></td>
              ))}
              <td></td>
            </tr>
            {currentReport.map((row, i) => (
              <tr
                key={i}
                className="list-hover"
                style={{ height: "30px", cursor: "pointer" }}
                onClick={() => {
                  setCurrentReportItem(row);
                  setCurrentColumnsReportItem(commonColumns);
                }}
              >
                <td></td>
                {commonColumns.map((column) => {
                  const cellValue = row[column];
                  if (column.includes("Fecha")) {
                    return <td>{formatDateStandar(cellValue)}</td>;
                  } else if (column.includes("Facturable")) {
                    if (cellValue === true) {
                      return <td>Si</td>;
                    } else {
                      return <td>No</td>;
                    }
                  } else if (typeof cellValue === "object") {
                    if (Array.isArray(cellValue)) {
                      return (
                        <td key={column}>
                          {cellValue.map((item, index) => (
                            <div key={index}>
                              {Object.entries(item).map(([key, value]) => (
                                <div key={key}>
                                  <strong>{key}:</strong> {value?.toString()}
                                </div>
                              ))}
                            </div>
                          ))}
                        </td>
                      );
                    } else {
                      const keys = Object.keys(row[column]);
                      const values = Object.values(row[column]);
                      return (
                        <td>
                          {keys.map((key, i) => (
                            <div key={i}>
                              {key}: {values[i]}
                            </div>
                          ))}
                        </td>
                      );
                    }
                  } else {
                    return <td>{cellValue}</td>;
                  }
                })}
                <td></td>
              </tr>
            ))}
            <tr>
              <td></td>
              {commonColumns.map(() => (
                <td></td>
              ))}
              <td></td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return (
        <h4 style={{ color: "white", fontSize: "15px", textAlign: "center" }}>
          No se encuentran registros
        </h4>
      );
    }
  };

  const reportOptionsEditor = () => {
    const existsInSelected = (service: string, field: ReportField) => {
      if (currentReportOptions && currentReportOptions.clientActiveOptions) {
        const currentService =
          currentReportOptions.clientActiveOptions![service];
        if (currentService.find((item) => item.fieldName === field.fieldName)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const handleSelectedChange = (service: string, field: ReportField) => {
      if (currentReportOptions && currentReportOptions.clientActiveOptions) {
        const clientActiveOptions = {
          ...currentReportOptions.clientActiveOptions,
        }; // Ensure it's a mutable copy
        const currentService = clientActiveOptions[service] || [];

        if (currentService.find((item) => item.fieldName === field.fieldName)) {
          // Remove the field if it exists
          clientActiveOptions[service] = currentService.filter(
            (item) => item.fieldName !== field.fieldName
          );
        } else {
          // Add the field if it doesn't exist
          clientActiveOptions[service] = [...currentService, field];
        }

        // Replace the entire clientActiveOptions object
        const newCurrentReportOptions = {
          ...currentReportOptions,
          clientActiveOptions: { ...clientActiveOptions },
        };
        dispatch(setReportOptions(newCurrentReportOptions));
        dispatch(changeReportOptions({ newReportFields: clientActiveOptions }));
      }
    };

    if (
      currentReportOptions &&
      currentReportOptions.clientActiveOptions &&
      currentReportOptions.allOptions
    ) {
      const services = Object.keys(currentReportOptions.clientActiveOptions);
      const commonElements = services.map((service) => {
        const fields = currentReportOptions.allOptions![service];
        return { service: service, fields };
      });

      return commonElements.map((element, i) => {
        return (
          <div
            key={i}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <h5
              style={{
                color: "black",
                fontSize: "20px",
                textAlign: "start",
                width: "100%",
                fontWeight: 600,
                marginTop: "15px",
                marginBottom: "10px",
              }}
            >
              {serviceIdToName(element.service)}
            </h5>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              {element.fields.map((field, j) => {
                return (
                  <FormControlLabel
                    key={j}
                    style={{
                      color: "#000",
                      fontFamily: "Open Sans",
                      alignSelf: "start",
                    }}
                    control={
                      <Checkbox
                        style={{ color: "#000" }}
                        checked={existsInSelected(element.service, field)}
                        onChange={() => {
                          handleSelectedChange(element.service, field);
                        }}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "Open Sans",
                          textAlign: "start",
                        }}
                      >
                        {field.fieldName}
                      </span>
                    }
                  />
                );
              })}
            </div>
          </div>
        );
      });
    } else {
      return (
        <h5 style={{ color: "white", fontSize: "15px", textAlign: "center" }}>
          No se encontró la configuración intente de nuevo
        </h5>
      );
    }
  };

  return (
    <LoadingOverlay
      active={loading === "pending" || loadingBillInfo === "pending"}
      spinner={<ClipLoader color="white" size={"100px"} />}
    >
      <Modal
        isOpen={showModalReportFields}
        onRequestClose={() => dispatch(setShowReportFieldsModal(false))}
        style={customStylesModal}
        contentLabel="Campos de reporte"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {" "}
          <h3
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              alignSelf: "end",
              textAlign: "end",
              fontSize: "22px",
            }}
          >
            Campos Disponibles para reporte
          </h3>
          <div style={{ width: "100%", overflowY: "auto" }}>
            {reportOptionsEditor()}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
              width: "100%",
            }}
          >
            <p
              className="text-medium text-bold"
              style={{
                color: "black",
                width: "50%",
                textAlign: "start",
                alignSelf: "start",
              }}
            >
              Para aplicar los cambios debe seleccionar el periodo de
              facturación sobre el que quiere generar el reporte
            </p>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "6px",
                paddingBottom: "6px",
                paddingLeft: "20px",
                paddingRight: "20px",
                alignSelf: "end",
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(setShowReportFieldsModal(false));
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showModalFactura}
        onRequestClose={() => dispatch(setShowModalFactura(false))}
        style={customStylesModal2}
        contentLabel="Prefactura y factura"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {" "}
          <h3
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "20px",
              textAlign: "center",
              fontSize: "22px",
            }}
          >
            Facturación
          </h3>
          {showFactura()}
          {selectedPeriod?.billGenerated &&
            !selectedPeriod.billGenerated.invoiceIssued && (
              <p
                className="text-medium text-bold"
                style={{
                  color: "black",
                  width: "50%",
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Esta es una prefactura, verifique la información presentada aquí
                antes de emitirla dele al botón "Emitir Factura"
              </p>
            )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "45px",
              width: "50%",
            }}
          >
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "6px",
                paddingBottom: "6px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (
                  selectedPeriod?.billGenerated &&
                  selectedPeriod.billGenerated.invoiceIssued
                ) {
                  navigate(0);
                }
                dispatch(setShowModalFactura(false));
              }}
            >
              Cerrar
            </button>

            {selectedPeriod?.billGenerated &&
              !selectedPeriod.billGenerated.invoiceIssued && (
                <button
                  className="text-medium text-bold primary-color"
                  style={{
                    border: "none",
                    textAlign: "center",
                    borderRadius: "8px",
                    background: "#1A617D",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(confirmBill());
                  }}
                >
                  Emitir Factura
                </button>
              )}
          </div>
        </div>
      </Modal>
      {!currentReport ? (
        <div
          style={{
            width: "96%",
            margin: "20px",
            height: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            borderRadius: "10px",
          }}
        >
          <h3
            style={{
              color: "white",
              width: "50%",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Para generar reportes o ver reportes anteriores seleccione el
            cliente y periodo que quiere consultar{" "}
          </h3>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "96%",
              maxHeight: "500px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              overflow: "auto",
            }}
          >
            <h4
              style={{
                color: "white",
                fontSize: "18px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              {serviceIdToName(currentReportService)}
            </h4>
            {generateReportTable()}
          </div>
          <div
            style={{
              marginTop: "40px",
              width: "60%",
              maxHeight: "240px",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "auto",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            }}
          >
            {currentReportItem ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "50%" }}>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Datos Basicos
                  </h5>
                  {showReportItem()}
                </div>
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    style={{
                      color: "#FFF",
                      fontFamily: "Open Sans",
                      alignSelf: "end",
                    }}
                    control={
                      <Checkbox
                        style={{ color: "#FFF" }}
                        checked={currentReportItem["Facturable"]}
                        onChange={() => {
                          const newReportItem = { ...currentReportItem };
                          newReportItem["Facturable"] =
                            !currentReportItem["Facturable"];
                          setCurrentReportItem(newReportItem);
                          const reportWithoutCurrent = currentReport.filter(
                            (item) => item !== currentReportItem
                          );
                          dispatch(
                            changeUnbillable({
                              entityId: currentReportItem["Id"],
                              unbillable: !newReportItem["Facturable"],
                              reason: "",
                            })
                          );
                          dispatch(
                            setCurrentReport({
                              report: [...reportWithoutCurrent, newReportItem],
                              name: currentReportService!,
                            })
                          );
                        }}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "Open Sans",
                        }}
                      >
                        ¿Marcar como facturable?
                      </span>
                    }
                  />
                </div>
              </div>
            ) : (
              <h4
                style={{ color: "white", fontSize: "14px", fontWeight: "600" }}
              >
                Seleccione un registro para ver más acciones
              </h4>
            )}
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
