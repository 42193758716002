import ClipLoader from "react-spinners/ClipLoader";
import { useAppSelector } from "../../../../../app/hooks";
import { selectGenerateReport } from "../../BillingSlice";
import { formatCurrency, getFormattedDates } from "../../helpers/utils";
import "./../../BillingStyles.scss";

export const TableComponent = ({
  data,
  title,
  downloadReport,
  rangeInDays,
}: {
  data: Record<string, Record<string, number>>;
  title: string;
  downloadReport: () => void;
  rangeInDays: number;
}) => {
  const allDates = Array.from(
    new Set(
      Object.values(data).flatMap((clientData) => Object.keys(clientData))
    )
  ).sort();

  const rows = Object.keys(data);
  const date = getFormattedDates(allDates, rangeInDays);
  const loadingReport = useAppSelector(selectGenerateReport);

  return (
    <div className="table-container">
      <table className="table responsive-table">
        <thead>
          <tr>
            <th>
              <div className="date-label">Fecha</div>
              <div className="title-label">{title}</div>
              <div className="diagonal-line"></div>
            </th>
            {date.map((date, index) => (
              <th key={index}>{date}</th>
            ))}
            <th className="total-cell">Total</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            const rowData = data[row];
            const rowTotal = allDates.reduce(
              (sum, date) => sum + (rowData[date] || 0),
              0
            );

            return (
              <tr key={row}>
                <td>{row}</td>
                {allDates.map((date) => (
                  <td key={date}>
                    {rowData[date] ? formatCurrency(rowData[date]) : "-"}
                  </td>
                ))}
                <td className="total-cell">{formatCurrency(rowTotal)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="download-container">
        <p className="primary-color" style={{ width: "80%" }}>
          Aquí podrás descargar un archivo CSV con los filtros seleccionados,
          este lo podrás importar en cualquier hoja de cálculo para realizar
          cálculos y validaciones.
        </p>
        <button
          className="download-button primary-color"
          onClick={(e) => {
            e.preventDefault();
            downloadReport();
          }}
        >
          {loadingReport === "pending" ? (
            <div style={{ width: "100%" }}>
              <ClipLoader color="white" size="25px" />
            </div>
          ) : (
            <>Descargar reporte detallado</>
          )}
        </button>
      </div>
    </div>
  );
};
