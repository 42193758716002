import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  dataGeneralIndicator,
  getDataClients,
  selectBillingComponent,
  selectCurrentFiltered,
  selectDataClients,
  selectDataIndicators,
  selectLoading,
} from "../BillingSlice";
import {
  GeneralIndicatorsCard,
  EchartsGraphBasicBar,
  EchartsGraphPie,
} from "./components";

import "../BillingStyles.scss";
import { convertDateStandard } from "../../../../helpers/utils";
import { ReactComponent as BarIcon } from "../../../../Assets/common/images/barGraphIcon.svg";
import { ReactComponent as PieIcon } from "../../../../Assets/common/images/iconPie.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";

export const General = () => {
  const filteredData = useAppSelector(selectCurrentFiltered);
  const currentFilters = useAppSelector(selectCurrentFiltered);
  const dataClients = useAppSelector(selectDataClients);
  const generalIndicators = useAppSelector(selectDataIndicators);
  const activeComponent = useAppSelector(selectBillingComponent);
  const loading = useAppSelector(selectLoading);
  const [view, setView] = useState<"bar" | "pie">("bar");
  const dispatch = useAppDispatch();
  const {
    total,
    estimated,
    valueToGoal,
    percentChange,
    monthOrWeeklyStart,
    monthOrWeeklyEnd,
    serviceCount,
    dateCount,
    clientCount,
  } = generalIndicators ?? {};

  const { formatYearDate, formatUTCStartDate, formatUTCEndDate, monthUTCName } =
    convertDateStandard(monthOrWeeklyStart, monthOrWeeklyEnd) || {};

  useEffect(() => {
    if (activeComponent !== "General") {
      dispatch(changeActiveComponent("General"));
    }
  }, [activeComponent]);

  useEffect(() => {
    dispatch(
      dataGeneralIndicator({
        dataFiltered: {
          ...filteredData,
          endDate: null,
          startDate: null,
          clientId: null,
          accumulated: false,
          hfId: null,
          visualizationType: null,
        },
      })
    );
    if (!dataClients) {
      dispatch(getDataClients());
    }
  }, [currentFilters]);

  const convertDateToday = () => {
    if (!dateCount) {
      return {};
    }
    const keys = Object.keys(dateCount);
    const value = Object.values(dateCount);

    const result = keys.map((key) => convertDateStandard(key));

    if (result) {
      const newDateCount = result.reduce<Record<number, number>>(
        (acc, item, index) => {
          if (item && item.formatUTCStartDate) {
            acc[item.formatUTCStartDate] = value[index];
          }
          return acc;
        },
        {}
      );

      return newDateCount;
    }
  };

  const newDateCount = convertDateToday();

  const onChangeView = () => {
    setView((prevView) => (prevView === "bar" ? "pie" : "bar"));
  };

  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <div className="h-100 p-3 overflow-auto">
        <div className="flex-col conteiner-indicators-card">
          <div className="conteiner-indicators-dates">
            <p>Indicadores generales</p>
            {
              <p>
                ({formatUTCStartDate} - {formatUTCEndDate} de {monthUTCName}{" "}
                {formatYearDate})
              </p>
            }
          </div>
          <div className="conteiner-indicators">
            <GeneralIndicatorsCard
              title="Valor a recaudar"
              value={total ?? 0}
            />
            <GeneralIndicatorsCard
              title="Cambio Porcentual respecto a rango anterior"
              value={percentChange ?? 0}
              colorRules={{
                positive: "#66FF66",
                negative: "#FF8A37",
                zero: "#ffff",
              }}
              isPercentage
            />
            <GeneralIndicatorsCard
              title="Valor estimado mensual"
              value={estimated ?? 0}
              hideIfNegativeOne
            />
            {currentFilters.simpleDateFilter !== "WEEK" && (
              <GeneralIndicatorsCard
                title="Faltante para meta"
                value={valueToGoal ?? -1}
                colorRules={{
                  positive: "#FF8A37",
                  negative: "#66FF66",
                  zero: "#ffffff",
                }}
                hideIfNegativeOne
              />
            )}
          </div>
        </div>
        <div className="conteiner-indicators-card mt-3">
          <div className="conteiner-indicators-dates">
            <p className="">Progreso Mensual</p>
            {
              <p>
                ({formatUTCStartDate} - {formatUTCEndDate} de {monthUTCName}{" "}
                {formatYearDate})
              </p>
            }
          </div>
          {
            <>
              <div
                style={{
                  backgroundColor: "rgb(0,0,0,0.2)",
                  borderRadius: "8px",
                }}
              >
                {dateCount && (
                  <EchartsGraphBasicBar
                    data={newDateCount || {}}
                    title="Valor a recaudar"
                  />
                )}
              </div>
            </>
          }
        </div>

        <div className="conteiner-indicators-card mt-2">
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                onChangeView();
              }}
              className="button-style"
            >
              {view === "bar" ? <PieIcon /> : <BarIcon />}
            </button>
          </div>

          <div className="conteiner-indicators-dates">
            <p>Destacado</p>
            <p>
              ({formatUTCStartDate} - {formatUTCEndDate} de {monthUTCName}{" "}
              {formatYearDate})
            </p>
          </div>

          {view === "bar" && (
            <div className="graph-container">
              <div className="graph-card">
                <EchartsGraphBasicBar
                  data={clientCount ?? {}}
                  title="Por cliente"
                />
              </div>

              <div className="graph-card">
                <EchartsGraphBasicBar
                  data={serviceCount ?? {}}
                  title="Por servicio"
                />
              </div>
            </div>
          )}
          {view === "pie" && (
            <div className="graph-container">
              <div className="graph-card">
                <p>Por cliente</p>
                <EchartsGraphPie data={clientCount ?? {}} />
              </div>

              <div className="graph-card">
                <p>Por servicio</p>
                <EchartsGraphPie data={serviceCount ?? {}} />
              </div>
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};
