import Modal from "react-modal";

interface AlertPopUpPros {
  title: string;
  showModal: boolean;
  mensaje: string;
  onClose?: () => void;
  items?: JSX.Element;
}

export const AlertPopUp: React.FC<AlertPopUpPros> = ({
  title,
  showModal,
  mensaje,
  onClose,
  items,
}) => {
  return (
    <Modal
      overlayClassName="modal-overlay"
      className="modal-container"
      isOpen={showModal}
      onRequestClose={onClose}
    >
      <div className="modal-content">
        <div className="modal-items">{items}</div>
        <p className="modal-message">{title}</p>
        <p>{mensaje}</p>
      </div>
    </Modal>
  );
};
