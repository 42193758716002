import { useEffect, useState } from "react";
import { formatCurrency } from "../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  getDataClients,
  getGeneralConfig,
  getGeneralConfigByClient,
  selectBillingComponent,
  selectConfigClientId,
  selectDataClients,
  selectGeneralConfig,
  selectGeneralConfigByClient,
  selectLoading,
  selectNameClient,
  updateGeneralConfig,
} from "../BillingSlice";

import { ClientConfig } from "./components/ClientConfig";

import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
export const Config = () => {
  const dispatch = useAppDispatch();
  const generalConfig = useAppSelector(selectGeneralConfig);
  const configClient = useAppSelector(selectGeneralConfigByClient);
  const nameClient = useAppSelector(selectNameClient);
  const loading = useAppSelector(selectLoading);
  const clientId = useAppSelector(selectConfigClientId);
  const [newGoalValue, setNewGoalValue] = useState<number | string>(
    formatCurrency(generalConfig?.goalValue ?? 0)
  );
  const [inputValue, setInputValue] = useState<number>();
  const [shouldRefreshConfig, setShouldRefreshConfig] = useState(false);
  const dataClients = useAppSelector(selectDataClients);
  const activeComponent = useAppSelector(selectBillingComponent);

  useEffect(() => {
    if (!dataClients) {
      dispatch(getDataClients());
    }

    if (!generalConfig) {
      dispatch(getGeneralConfig());
    }

    if (clientId) {
      dispatch(getGeneralConfigByClient(clientId));
    }

    if (shouldRefreshConfig) {
      dispatch(getGeneralConfig());
      setShouldRefreshConfig(false);
    }
  }, [clientId, generalConfig, shouldRefreshConfig, dispatch]);

  useEffect(() => {
    setNewGoalValue(formatCurrency(generalConfig?.goalValue ?? 0));
  }, [generalConfig]);

  useEffect(() => {
    if (activeComponent !== "Config") {
      dispatch(changeActiveComponent("Config"));
    }
  }, [activeComponent]);

  const goalValue = formatCurrency(generalConfig?.goalValue ?? 0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^0-9]/g, "");
    const numericValue = parseInt(rawValue, 10);

    if (!isNaN(numericValue)) {
      setInputValue(numericValue);
      setNewGoalValue(formatCurrency(numericValue));
    } else {
      setNewGoalValue(0);
    }
  };

  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <div className="h-100 p-3 overflow-auto">
        <div
          className="flex-col conteiner-indicators-card"
          style={{ height: "30%" }}
        >
          <p
            className="primary-color"
            style={{ textAlign: "center", fontWeight: "700" }}
          >
            General
          </p>
          <div
            style={{
              backgroundColor: "rgb(0,0,0,0.2)",
              width: "46%",
              height: "95%",
              borderRadius: "8px",
              margin: "5px",
            }}
          >
            <p
              style={{ textAlign: "center", fontWeight: "700" }}
              className="primary-color"
            >
              Meta mensual
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ width: "40%", marginBottom: "5px" }}>
                <p
                  className="primary-color"
                  style={{ margin: "0px", fontSize: "11px" }}
                >
                  Valor
                </p>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    backgroundColor: "rgb(255,255,255,0.1)",
                    border: "none",
                    color: "white",
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "5px",
                  }}
                  value={newGoalValue}
                  onChange={handleChange}
                />
              </div>
              {newGoalValue !== goalValue && (
                <div
                  style={{
                    width: "55%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "rgba(0,0,0,0.2)",
                      marginTop: "5px",
                      border: "none",
                      borderRadius: "6px",
                      width: "80%",
                    }}
                    className="primary-color"
                    onClick={() => {
                      if (inputValue) {
                        dispatch(updateGeneralConfig(inputValue)).then(() => {
                          setShouldRefreshConfig(true);
                        });
                      }
                    }}
                    disabled={!inputValue}
                  >
                    Actualizar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=" conteiner-indicators-card mt-2" style={{}}>
          {" "}
          {clientId ? (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  marginTop: "5px",
                }}
                className="primary-color"
              >
                {nameClient}
              </p>
              <ClientConfig config={configClient}></ClientConfig>
            </>
          ) : (
            <div
              style={{
                width: "96%",
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "600",
                  width: "30%",
                }}
                className="primary-color"
              >
                Seleccione un cliente para visualizar la configuración del mismo
              </p>
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};
