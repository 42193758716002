import { useEffect, useState } from "react";
import { ActionMeta, SingleValue } from "react-select";
import Select from "react-select";

export interface DropDownProps {
  maxContent?: boolean;
  alignCenter?: boolean;
  options: { value: string; label: string }[];
  valueKey?: string;
  title: string;
  titleColor?: string;
  errorMessage?: string;
  invisible?: boolean;
  defaultValue?: { value: string; label: string };
  isClearable?: boolean;
  useFirstOptionAsDefault?: boolean;
  onChange: (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => void;
}
export const DropDown = (props: DropDownProps) => {
  const [isClearable, setIsClearable] = useState<boolean>(false);
  useEffect(() => {
    if (props.isClearable !== undefined) {
      setIsClearable(props.isClearable);
    } else {
      setIsClearable(true);
    }
    return () => {};
  }, []);

  const initialValue = props.defaultValue
  ? props.defaultValue
  : props.useFirstOptionAsDefault && props.options.length > 0
  ? props.options[0]
  : { label: "Seleccione", value: "" };


  return (
    <div
      className="flex-col"
      style={{
        width: "100%",
        alignItems: props.alignCenter ? "center" : "start",
        justifyContent: "space-evenly",
        marginTop: "5px",
        marginBottom: "10px",
      }}
    >
      <div
        className="text-normal text-medium primary-color"
        style={{
          width: props.maxContent ? "max-content" : "min-content",
          marginLeft: "5px",
          marginBottom: "5px",
          color: props.titleColor ? props.titleColor : "white",
        }}
      >
        {props.title}
      </div>
      <Select
        maxMenuHeight={100}
        styles={{
          control: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.5)",
            borderColor: "rgba(0,0,0,0.5)",
            color: "white",
            marginLeft: "5px",
            marginRight: "5px",
            width: "100%",
          }),
          menu: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.5)",
            borderColor: "rgba(0,0,0,0.5)",
          }),
          menuList: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.5)",
            borderColor: "rgba(0,0,0,0.5)",
            color: "white",
            width: "180%",
            height: "150%",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "white",
          }),
          option: (provided, state) => ({
            ...provided,
            background: state.isFocused ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.5)",
            "&:hover": {
              background: "rgba(0,0,0,0.8)",
            },
          }),
        }}
        options={props.options}
        onChange={props.onChange}
        defaultValue={initialValue}
        isClearable={isClearable}
      />
    </div>
  );
};

export const DropDownPagare = (props: DropDownProps) => {
  const getDropdownValueFromKey = (
    key?: string,
    defaultValue?: { value: string; label: string }
  ) => {
    if (!key) {
      return defaultValue;
    } else {
      switch (key) {
        case "nit":
          return { label: "NIT", value: "nit" };
        case "cc":
          return { label: "Cédula de ciudadanía", value: "cc" };
        case "Natural":
          return { label: "Natural", value: "Natural" };
        case "Juridica":
          return { label: "Jurídica", value: "Juridica" };
        default:
          return undefined;
      }
    }
  };
  if (props.invisible) {
    return null;
  }
  return (
    <div
      className="flex-col"
      style={{
        alignItems: "start",
        justifyContent: "space-evenly",
        margin: "5px 1% 10px 0px",
      }}
    >
      <div
        style={{
          marginLeft: "5px",
          marginBottom: "5px",
          fontSize: "12px",
          color: "white",
          fontWeight: "600",
        }}
      >
        {props.title}
      </div>
      <Select
        maxMenuHeight={100}
        styles={{
          control: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.2)",
            borderColor: "rgba(0,0,0,0.2)",
            color: "white",
            marginLeft: "5px",
            marginRight: "5px",
            width: "155px",
          }),
          menu: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.2)",
            borderColor: "rgba(0,0,0,0.2)",
          }),
          menuList: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.2)",
            borderColor: "rgba(0,0,0,0.2)",
            color: "white",
            width: "155px",
            height: "100%",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "white",
          }),
          option: (provided, state) => ({
            ...provided,
            background: state.isFocused ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.2)",
            "&:hover": {
              background: "rgba(0,0,0,0.6)",
            },
          }),
        }}
        options={props.options}
        onChange={props.onChange}
        defaultValue={getDropdownValueFromKey(
          props.valueKey,
          props.defaultValue
        )}
        isClearable={props.isClearable}
      />
      <p
        style={{
          fontSize: "10px",
          color: "rgba(255, 105, 105, 0.7)",
          fontWeight: "400",
          textAlign: "end",
          margin: "2px 2px 0 0",
          visibility: props.errorMessage ? "visible" : "hidden",
        }}
      >
        {props.errorMessage}
      </p>
    </div>
  );
};
