interface TopClientsProps {
  dataClients: Record<string, number>;
  title: string;
}

export const TopComponent: React.FC<TopClientsProps> = ({
  dataClients,
  title,
}) => {
  const getSortedClients = (dataClients: Record<string, number>) => {
    const sortedClients = Object.entries(dataClients)
      .sort(([, valueA], [, valueB]) => valueB - valueA)
      .slice(0, 5);
    return sortedClients;
  };

  const sortedClients = getSortedClients(dataClients);

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "15px",
        fontSize: "10px",
        fontWeight: "700",
      }}
      className="primary-color"
    >
      <p style={{ textAlign: "center" }}>{title}</p>
      <ol style={{ paddingLeft: "20px" }}>
        {sortedClients.map(([name, value], index) => (
          <li key={index} style={{ marginBottom: "8px" }}>
            {name}
          </li>
        ))}
      </ol>
    </div>
  );
};
