import { useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../common/commonStyles.scss";
import { ReactComponent as DownMenu } from "../../../../Assets/common/images/downMenu.svg";
import { ReactComponent as UpMenu } from "../../../../Assets/common/images/upMenu.svg";
import es from "date-fns/locale/es";
import { format } from "date-fns";

export interface FilterComponentProps {
  startDate?: Date | null;
  endDate?: Date | null;
  setStartDate?: Function;
  setEndDate?: Function;
  items?: Array<JSX.Element>;
  hasCalendar: boolean;
  calendarType?: "range" | "double";
  resetFunction?: Function;
  open?: boolean;
}

export const FilterComponent = (props: FilterComponentProps) => {
  registerLocale("es", es as any);
  const [isExpanded, setIsExpanded] = useState(false);
  const onChange = (dates: [start: Date | null, end: Date | null]) => {
    const [start, end] = dates;
    if (props.setStartDate && props.setEndDate) {
      props.setStartDate(start);
      props.setEndDate(end);
    }
  };

  const onChangeDouble = (date: Date | null, type: "start" | "end") => {

    if (date === null && props.setStartDate && props.setEndDate) {
      props.setStartDate(null);
      props.setEndDate(null);
    } else {
      if (type === "start" && props.setStartDate) {
        props.setStartDate(date);
      } else if (type === "end" && props.setEndDate) {
        props.setEndDate(date);
      }
    }
  };
  
  const onReset = () => {
    if (props.resetFunction) {
      props.resetFunction();
    }
  };

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });
  useEffect(() => {
    if (props.open) {
      setIsExpanded(true);
    }
  }, [props.open]);
  return (
    <>
      <div
        className="text-normal text-xxx-small primary-color-30"
        style={{ marginBottom: "5px" }}
      >
        | MENU DESPLEGABLE |
      </div>
      <button
        className="flex-col"
        {...getToggleProps({
          onClick: (e) => {
            e.preventDefault();
            setIsExpanded(!isExpanded);
          },
        })}
        style={{
          background: "rgba(0,0,0,0.2)",
          border: "none",
          borderRadius: isExpanded ? "8px 8px 0px 0px" : "8px",
          width: "95%",
          justifyContent: "center",
          alignItems: "center",
          height: isExpanded ? "" : "90px",
          boxSizing: "border-box",
        }}
      >
        <div
          className="flex-col"
          style={{
            alignItems: "center",
            justifyContent: "start",
            height: "100%",
            width: "80%",
            paddingTop: "5px",
          }}
        >
          <div className="text-bold text-medium primary-color">
            Filtro de busqueda
          </div>
          {isExpanded ? (
            ""
          ) : (
            <>
              {props.hasCalendar ? (
                <>
                  {" "}
                  <div className="text-normal text-x-small primary-color">
                    1. Seleccione el día o el rango de fecha a consultar
                  </div>
                  <div className="text-normal text-x-small primary-color">
                    2. Características especiales
                  </div>
                </>
              ) : (
                <div className="text-normal text-x-small primary-color">
                  Aquí podrás realizar una busqueda a través de filtros
                  especiales
                </div>
              )}
            </>
          )}
        </div>
      </button>
      {isExpanded ? (
        <div style={{ width: "0", height: "0" }}></div>
      ) : (
        <div
          className="flex-col"
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "0",
            height: "0",
          }}
        >
          <DownMenu
            onClick={(e) => {
              e.preventDefault();
              setIsExpanded(!isExpanded);
            }}
            style={{ position: "absolute" }}
          />
        </div>
      )}
      <section
        {...getCollapseProps({
          style: {
            background: "rgba(0,0,0,0.2)",
            borderRadius: "0px 0px 8px 8px ",
            width: "95%",
          },
        })}
      >
        <div
          className="flex-row"
          style={{
            justifyContent: "center",
            paddingTop: "10px",
            paddingBottom: "25px",
            display: "grid",
          }}
        >
          {props.hasCalendar ? (
            <>
              <ReactDatePicker
                locale="es"
                selected={props.startDate}
                onChange={onChange}
                startDate={props.startDate}
                endDate={props.endDate}
                selectsRange
                inline
                minDate={new Date("2020-01-01")}
                maxDate={new Date()}
              />
              <div
                style={{
                  visibility: props.resetFunction ? "visible" : "hidden",
                  background: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "4px",
                  fontWeight: "700",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onReset();
                }}
              >
                Limpiar Fecha
              </div>
            </>
          ) : (
            ""
          )}
          {props.calendarType && props.calendarType === "double" && (
            <>
              <div
                className="text-normal text-medium primary-color"
                style={{
                  marginLeft: "5px",
                  marginBottom: "5px",
                }}
              >
                Fecha Inicio
              </div>
              <ReactDatePicker
                locale="es"
                selected={props.startDate}
                dateFormat={"dd/MM/yyyy"}
                onChange={(date) => {
                  // Set date to start of the day
                  if (date) {
                    date.setHours(0, 0, 0, 0);
                  }
                  onChangeDouble(date, "start");
                }}
                minDate={new Date("2020-01-01")}
                maxDate={new Date()}
                isClearable={true}
                customInput={
                  <input
                    disabled={true}
                    id="name"
                    name="name"
                    type="text"
                    value={
                      props.startDate
                        ? format(props.startDate!, "dd/MM/yyyy")
                        : ""
                    }
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />
                }
              />
              <div
                className="text-normal text-medium primary-color"
                style={{
                  marginLeft: "5px",
                  marginBottom: "5px",
                }}
              >
                Fecha Final
              </div>
              <ReactDatePicker
                locale="es"
                selected={props.endDate}
                isClearable={true}
                onChange={(date) => {
                  // Set date to end of the day
                  if (date) {
                    date.setHours(23, 59, 59, 999);
                  }
                  onChangeDouble(date, "end");
                }}
                dateFormat={"dd/MM/yyyy"}
                minDate={new Date("2020-01-01")}
                maxDate={new Date()}
                customInput={
                  <input
                    disabled={true}
                    id="name"
                    name="name"
                    type="text"
                    value={
                      props.endDate ? format(props.endDate!, "dd/MM/yyyy") : ""
                    }
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />
                }
              />
            </>
          )}
          <div className="flex-col" style={{ width: "100%" }}>
            {props.items &&
              props.items.map((element) => {
                return element;
              })}
          </div>
        </div>
      </section>
      {!isExpanded ? (
        <div style={{ width: "0", height: "0" }}></div>
      ) : (
        <div
          className="flex-col"
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "0",
            height: "0",
          }}
        >
          <UpMenu
            onClick={(e) => {
              e.preventDefault();
              setIsExpanded(!isExpanded);
            }}
            style={{ position: "absolute" }}
          />
        </div>
      )}
    </>
  );
};
