import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import type { EChartsOption } from "echarts";
import { formatCurrency } from "../../helpers/utils";

export interface DataPoint {
  [key: string]: number;
}

const formatLabel = (label: string) => {
  const width = window.innerWidth;
  if (width > 1794 || width < 1323) {
    const maxLength = 15;
    if (label.length > maxLength) {
      const firstLine = label.substring(0, maxLength);
      const secondLine = label.substring(maxLength);
      return `${firstLine}\n${secondLine}`;
    }
    return label;
  }
  return label;
};

export const EchartsGraphBasicBar = ({
  data,
  title,
}: {
  data: DataPoint;
  title: string;
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const myChartRef = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (!data) return;

    if (chartRef.current) {
      myChartRef.current = echarts.init(chartRef.current);
    }

    const dates = Object.keys(data);
    const values = Object.values(data);

    const getFontSize = () => {
      const width = window.innerWidth;
      if (width <= 600) {
        return 10;
      } else if (width <= 1200) {
        return 12;
      } else {
        return 13;
      }
    };

    const option: EChartsOption = {
      title: {
        text: `${title}`,
        left: "center",
        textStyle: {
          color: "#ffffff",
          fontSize: 15,
        },
      },

      tooltip: {
        trigger: "axis",
        backgroundColor: "#2c3e50",
        borderColor: "#2980b9",
        borderWidth: 1,
        textStyle: {
          color: "#ecf0f1",
          fontSize: 12,
        },
        formatter: (params: any) => {
          let content = `<div style="font-weight: bold; margin-bottom: 5px;">${params[0].axisValue}</div>`;
          params.forEach((item: any) => {
            content += `
              <div style="margin: 5px 0; display: flex; align-items: center;">
                <span style="display:inline-block;width:10px;height:10px;background-color:${
                  item.color
                };margin-right:5px;border-radius:50%;"></span>
                ${item.seriesName}: <strong>${formatCurrency(
              item.value
            )}</strong>
              </div>
            `;
          });
          return content;
        },
      },

      xAxis: {
        type: "category",
        data: dates,
        axisLabel: {
          color: "#ffffff",
          fontSize: getFontSize(),
          interval: 0,
          formatter: (value: string) => formatLabel(value),
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "#ffffff",
          fontSize: 14,
        },
      },
      series: [
        {
          name: "Valor",
          data: values,
          type: "bar",
          itemStyle: {
            color: "#537FF1",
          },
          emphasis: {
            itemStyle: {
              color: "#2980b9",
            },
          },
        },
      ],
    };

    myChartRef.current?.setOption(option);

    const handleResize = () => {
      myChartRef.current?.resize();
      myChartRef.current?.setOption({
        xAxis: {
          axisLabel: {
            fontSize: getFontSize(),
          },
        },
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      myChartRef.current?.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [data]);

  return <div ref={chartRef} style={{ width: "100%", height: "35vh" }} />;
};
